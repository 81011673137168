
@import url('react-chat-widget/lib/styles.css');

.rcw-conversation-container {
    min-width: 500px;
    width: 500px;
}

.rcw-conversation-container > .rcw-header {
    background-color: #048219;
}

.rcw-launcher {
    background-color: #048219;
}

.rcw-sender > .rcw-send {
    color: #048219;
}

.rcw-response > .rcw-message-text {
    max-width: 400px
}

.rcw-client > .rcw-message-text {
    max-width: 400px
}

#chat-widget-container {
    right: 0px;
    left: unset;
}

@media (max-width: 600px) {
    .rcw-conversation-container {
        min-width: 350px;
        order: 2
    }
}

